<template>
  <div class="userInfo_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div class="content">
      <van-field name="uploader" label="头像">
        <template #input>
          <div class="upPhoto">
            <input class="file" type="file" accept="image/*" @change="handleFile" ref="refIdInput" />
            <van-image class="photo" round :src="avatarUrl">
              <template v-slot:error>
                <van-icon name="plus" size="20" />
              </template>
            </van-image>
          </div>
        </template>
      </van-field>
      <van-field v-model="nickName" label="昵称" placeholder="请输入昵称" />
      <van-field name="radio" label="性别">
        <template #input>
          <van-radio-group v-model="gender" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field label="二维码" input-align="right" @click="showDialog=!showDialog">
        <template #input>
          <van-icon name="qr" color="#999999" size="20" />&nbsp;&nbsp;
          <van-icon name="arrow" color="#999999" size="20" />
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="default" @click="goPage('editPassword')">修改密码</van-button>
      </div>
      <div style="margin: 16px;">
        <van-button round block type="info" @click="onSubmit">保存</van-button>
      </div>
    </div>
    <div>
      <van-dialog v-model="showDialog" title="二维码" show-cancel-button style="text-align:center">
        <vue-qr :logoSrc="avatarUrl" :text="'hjc|0x01|'+userInfo.userId" :size="200"></vue-qr>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
import { mapState } from 'vuex'
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader,
    vueQr,
  },
  computed: {
    ...mapState({
      userInfo: state => state.common.userInfo
    })
  },
  data() {
    return {
      title: this.$route.meta.title,
      avatarUrl: '', // 头像URL
      nickName: '', // 昵称
      gender: '', // 性别 1男 0女
      uploader: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      showDialog: false,
      // imageUrl: require(this.avatarUrl),
    }
  },
  created() {
    this.onLoad()
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    console.log(this.userInfo)
  },
  methods: {
    onLoad() {
      this.avatarUrl = this.userInfo.avatarUrl || ''
      this.nickName = this.userInfo.nickName || ''
      this.gender = this.userInfo.gender || ''
    },
    handleFile(e) {
      var files = this.$refs.refIdInput.value;
      if (!/\.(gif|jpg|jpeg|png|gif|jpg|png)$/i.test(files)) {
        this.$toast("图片类型必须是.gif,jpeg,jpg,png中的一种,请重新上传")
        return false;
      }
      let file = e.target.files[0]
      let param = new FormData()       // 创建form对象     
      param.append('file', file)       // 通过append向form对象添加数据
      let config = {
        headers: { 'Content-Type': 'multipart/form-data', access_token: this.$store.state.common.loginInfo.access_token }
      }
      this.$refs.refIdInput.value = ''
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true });
      this.$axios.post(this.$API['API_FILESYSTEM_FILEINFO_FILE'] + '?fileSource=QINIU', param, config).then((res) => {
        if (res?.data?.fileUrl) {
          this.avatarUrl = res.data.fileUrl
          this.$toast('上传成功');
        } else {
          this.$toast(res.data.content.resultMessage || '上传失败！');
        }
      }).catch(() => {
        this.$toast("图片上传失败，请重新上传!")
      })
    },
    async onSubmit() {
      const res = await this.$axios({
        method: "put",
        url: this.$API['API_USER_ANON_PROFILE'],
        data: { avatarUrl: this.avatarUrl, nickName: this.nickName, gender: this.gender },
        headers: { access_token: this.$store.state.common.loginInfo.access_token }
      })
      if (res.data && res.data.statusCode === 200) {
        this.$toast('保存成功');
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.userInfo_page {
  .content {
    margin-top: 12px;
    .upPhoto {
      position: relative;
      display: flex;
      justify-content: flex-end;
      .file {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }
      .photo {
        display: block;
        width: 65px;
        height: 65px;
        border-radius: 50%;
      }
    }
  }
}
</style>
